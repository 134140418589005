import { makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';
import content from '../../../content/index/intro.json';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions from '../../../styles/dimensions';
import { StaticImage } from 'gatsby-plugin-image';

interface Props {
	handleLinkClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Intro = (props: Props) => {
	const { handleLinkClick } = props;

	const classes = useClasses();

	return (
		<section className={classes.section}>
			<StaticImage
				src="../../../images/index/intro/introHeader.svg"
				alt=""
				layout="constrained"
				loading="eager"
				placeholder="blurred"
				objectFit="contain"
				objectPosition="50% 50%"
				quality={100}
				className={classes.banner}
				imgClassName={classes.bannerImage}
			/>

			<StaticImage
				src="../../../images/index/intro/introHeaderMobile.svg"
				alt=""
				layout="fullWidth"
				loading="eager"
				placeholder="blurred"
				objectFit="contain"
				objectPosition="50% 50%"
				className={classes.mobileBanner}
			/>

			<div className={classes.container}>
				<div className={classes.headerContainer}>
					<h1 className={classes.header}>{content.title}</h1>

					<p className={classes.text}>{content.subtitle}</p>
					<p className={classes.text}>{content.subtitle2}</p>
					<Link to={content.register.src} className={classes.link} onClick={handleLinkClick}>
						{content.register.text}
					</Link>
					<p className={classes.text}>{content.subtitle3}</p>
				</div>
			</div>
		</section>
	);
};

export default Intro;

const useClasses = makeStyles({
	section: {
		height: 'calc(100vh - 6.25em)',
		position: 'relative',
	},
	banner: {
		height: 'calc(100vh - 6.25em)',
		width: '100%',
		'@media (max-width: 599px)': {
			display: 'none !important',
		},
	},
	mobileBanner: {
		height: 'calc(100vh - 6.25em)',
		'@media (min-width: 600px)': {
			display: 'none !important',
		},
	},
	bannerImage: {
		'@media (max-aspect-ratio: 1.5)': {
			objectPosition: '35% 50% !important',
		},
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	header: {
		color: Colors.textBlack,
		whiteSpace: 'pre-wrap',
		transform: 'skewX(-20deg)',
		margin: 0,
		marginBottom: 0,
		textAlign: 'center',
		position: 'relative',
		left: '0.5em',
		'@media (max-width: 599px)': {
			transform: 'unset',
			left: 0,
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	},
	text: {
		color: Colors.textBlack,
		fontFamily: FiraSans.book,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	},
	link: {
		display: 'inline-block',
		borderRadius: '8px',
		padding: '0.875em 2.125em',
		backgroundColor: Colors.limeGreen,
		color: Colors.white,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
	},
});
