import { Card, CardActionArea, CardContent, makeStyles } from '@material-ui/core';
import React from 'react';
import content from '../../../content/index/services.json';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions from '../../../styles/dimensions';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { StaticImage } from 'gatsby-plugin-image';

const Services = () => {
	const classes = useClasses();

	const images = React.useMemo(() => {
		return [
			<StaticImage
				src="../../../images/index/services/DigitaleGeratepark.jpeg"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
				height={250}
				width={400}
			/>,
			<StaticImage
				src="../../../images/index/services/WellnessArea.jpeg"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
				height={250}
				width={400}
			/>,
			<StaticImage
				src="../../../images/index/services/PersonalTraining.jpeg"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
				height={250}
				width={400}
			/>,
		];
	}, []);

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<h2 className={classes.header}>{content.title}</h2>
				<p className={classes.subheader}>{content.subtitle}</p>

				<div className={classes.contentContainer}>
					<Card className={classes.root}>
						<CardActionArea>
							{images[0]}
							<CardContent>
								<h3>{content.tabs[0].text}</h3>
								<p className={classes.cardText}>{content.tabs[0].subtext}</p>
							</CardContent>
						</CardActionArea>
					</Card>

					<Card className={classes.root}>
						<CardActionArea>
							{images[1]}
							<CardContent>
								<h3>{content.tabs[1].text}</h3>
								<p className={classes.cardText}>{content.tabs[1].subtext}</p>
							</CardContent>
						</CardActionArea>
					</Card>

					<Card className={classes.root}>
						<CardActionArea>
							{images[2]}
							<CardContent>
								<h3>{content.tabs[2].text}</h3>
								<p className={classes.cardText}>{content.tabs[2].subtext}</p>
							</CardContent>
						</CardActionArea>
					</Card>
				</div>
			</div>
		</section>
	);
};

export default Services;

const useClasses = makeStyles({
	section: {
		backgroundColor: Colors.backgroundBlack,
		transform: 'skewY(-3deg)',
		paddingTop: '5em',
		paddingBottom: '8em',
		marginTop: '2em',
	},
	container: {
		transform: 'skewY(3deg)',
	},
	header: {
		textAlign: 'center',
		color: Colors.white,
		marginTop: 0,
		marginBottom: '0.3em',
	},
	subheader: {
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		textAlign: 'center',
		color: Colors.whiteDimmed,
		margin: 0,
	},
	contentContainer: {
		display: 'flex',
		justifyContent: 'center',
		gap: '16px',
		marginTop: '16px',
		flexWrap: 'wrap',
	},
	root: {
		maxWidth: 345,
	},
	media: {
		height: 140,
	},
	cardText: {
		color: Colors.textBlack,
		fontFamily: FiraSans.book,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallText.fontSize,
			lineHeight: Dimensions.smallText.lineHeight,
		},
	},
});
